import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PaginationContextProps, PublicationProps } from '../../types/pages'
import { ArticlePreviewWithAuthorAndCategoriesProps, AuthorProps } from '../../types/blog'
// Components
import { Layout, Seo } from '../../components/commons'
import { AuthorHero, BlogPaginatedArticles } from '../../components/blog'

interface DataQuery {
  blogUtils: {
    paginationPreviousLabel: string
    paginationNextLabel: string
    articleNumberLabelSingular: string
    articleNumberLabelPlural: string
  }
  author: AuthorProps & { locale: string; meta: PublicationProps }
  articlesList: {
    nodes: ArticlePreviewWithAuthorAndCategoriesProps[]
  }
}

interface PageContext extends PaginationContextProps {
  authorId: string
}

const Author: React.FC<PageProps<DataQuery, PageContext>> = ({ pageContext, data }) => {
  const { totalPages, currentPage, previousPagePath, nextPagePath, totalItems } = pageContext
  const {
    blogUtils: { paginationPreviousLabel, paginationNextLabel, articleNumberLabelSingular, articleNumberLabelPlural },
    author: { pageInfo, locale, meta },
    articlesList: { nodes: articles },
    author,
  } = data

  return (
    <Layout headerDark={pageInfo.headerDark}>
      <Seo slug={pageInfo.slug} publication={meta} lang={locale} content={pageInfo.seo} />
      <AuthorHero
        author={author}
        totalArticles={totalItems}
        articleNumberLabelSingular={articleNumberLabelSingular}
        articleNumberLabelPlural={articleNumberLabelPlural}
      />
      <BlogPaginatedArticles
        articles={articles}
        prevLabel={paginationPreviousLabel}
        previousPagePath={previousPagePath}
        nextLabel={paginationNextLabel}
        nextPagePath={nextPagePath}
        totalPages={totalPages}
        currentPage={currentPage}
      />
    </Layout>
  )
}

export const query = graphql`
  query AuhtorQuery($authorId: String!, $skip: Int!, $limit: Int!) {
    blogUtils: datoCmsBlog {
      paginationPreviousLabel
      paginationNextLabel
      articleNumberLabelSingular
      articleNumberLabelPlural
    }
    author: datoCmsAuthor(id: { eq: $authorId }) {
      locale
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      ...authorFragment
    }
    articlesList: allDatoCmsArticle(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { authors: { elemMatch: { id: { eq: $authorId } } } }
    ) {
      nodes {
        ...articlePreviewWithAuthorAndCategoriesFragment
      }
    }
  }
`

export default Author
